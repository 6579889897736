import React, { ComponentPropsWithoutRef, useState } from 'react';

import classNames from 'classnames';

import { Karma } from '@ui/uikit/components/icons/mono';
import { Attention } from '@ui/uikit/components/icons/poly';
import { formatAmountCompact } from '@ui/uikit/utils';

import { useNavLayout } from './Header';
import { ProfileMenuDialog } from './ProfileMenuDialog';
import { NavLayoutUser } from './types';
import UserAvatar from './UserAvatar';

interface ProfileButtonProps extends ComponentPropsWithoutRef<'button'> {
  user: NavLayoutUser;
  onLogout?: () => void;
  onSupport?: () => void;
}

export const ProfileButton = ({
  className,
  onLogout,
  onSupport,
  user,
  ...props
}: ProfileButtonProps) => {
  const { showKyc } = useNavLayout();
  const [isOpen, setIsOpen] = useState(false);

  const karma = user.data.attributes.karma;

  const isNeedVerification = !user.data.attributes.kyc.passed;

  const showKycWarning = showKyc && isNeedVerification;

  const openProfileMenu = () => setIsOpen(true);

  return (
    <>
      <button onClick={openProfileMenu} className="msq-btn md-msq:hidden">
        <UserAvatar
          src={user.data.attributes.icon}
          membershipTier={user.data.membershipTier.attributes.key}
          className="msq-avatar-md size-[2.25rem]"
        />
      </button>

      <button
        onClick={openProfileMenu}
        data-qa="user-profile-button"
        className={classNames(
          'msq-btn msq-btn-outline max-md-msq:hidden msq-btn-md !pl-[0.3125rem] gap-2 md-msq:gap-3 !py-1 pr-3 md-msq:pr-4 !rounded-full md-msq:msq-btn-lg max-md-msq:text-caption-sm-strong-m md-msq:msq-btn-lg',
          className,
        )}
        {...props}
      >
        <UserAvatar
          src={user.data.attributes.icon}
          membershipTier={user.data.membershipTier.attributes.key}
          className="msq-avatar-sm md-msq:msq-avatar-md"
        />

        <span className="inline-flex items-center gap-1 md-msq:gap-1.5">
          <Karma className="text-base-text-pink size-3 md-msq:size-4" />

          {formatAmountCompact(karma)}
        </span>

        {showKycWarning && <Attention className="size-4 text-base-text-orange" />}
      </button>

      <ProfileMenuDialog
        user={user}
        onLogout={onLogout}
        open={isOpen}
        onOpenChange={setIsOpen}
        onSupport={onSupport}
      />
    </>
  );
};
