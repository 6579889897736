import React from 'react';

import classNames from 'classnames';

import NavLayoutLink from './NavLayoutLink';
import { NavLayoutLinkBase } from './types';

interface QuickNavProps {
  items: NavLayoutLinkBase[];
  className?: string;
}

const QuickNav = ({ items, className }: QuickNavProps) => {
  return (
    <nav className={classNames('flex gap-1 md-msq:px-4', className)}>
      {items?.map((item) => {
        return (
          <NavLayoutLink
            className="msq-btn msq-btn-md px-0 msq-btn-secondary text-caption-sm-strong-m rounded-full flex-1"
            key={item.id}
            href={item.href}
            external={item.external}
          >
            {item.label}
          </NavLayoutLink>
        );
      })}
    </nav>
  );
};

export default QuickNav;
